import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import Introduction003 from './Introduction003'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;
  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }
  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;
    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }
    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }
    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: auto;
      padding: 10px 0 8px;
      margin-top: -10px;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }
    p {
      padding-bottom: 30px;
      line-height: 2;
      position: relative;
      span {
        background: linear-gradient(transparent 60%, #ff6 60%);
        font-weight: 600;
      }
      .p-list-link {
        position: absolute;
        top: 6px;
        right: 2px;
        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 7px;
          left: -14px;
          border-left: 8px solid #3ec7b3;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
        }
      }
    }
    .link-div {
      width: 100%;
      display: flex;
      justify-content: left;
      padding-bottom: 30px;
      > div {
        width: 50px;
      }
      .link-p {
        width: calc(100% - 100px);
        .p-list-link {
          position: absolute;
          top: -3px;
          left: 30px;
          &:before {
            display: block;
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 11px;
            left: -14px;
            border-left: 8px solid #3ec7b3;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
          }
        }
      }
    }
    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
    }
  }
  ${BreakPoints.large} {
  }
`

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner0009 = () => (
  <Wrapper>
    <ColumnH1 label="テレワークと通常時の業務における勤怠管理の必要性と問題点とは？" />
    {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
    <p className="p-txt-date">2022.04.15</p>
    <section>
      {/** TODO 川上申し送り事項：▼記事投稿（？）で作成された内容がここに入る予定 */}
      <p>
        新型コロナウイルス感染拡大防止の観点からテレワークの定着が一気に加速しました。しかし、テレワーク一択というわけにはいかず、通常の対面業務との
        <span>ハイブリッド型</span>で労務管理する企業が増えています。
        <br />
        今回はテレワークと通常時の業務における勤怠管理にフォーカスをあて解説していきます。
      </p>
      <img src="/images/column/details/c0009.jpg" alt="キンクラコラム画像9" />
      <h1>勤怠管理の必要性と問題点</h1>
      <p>
        勤怠管理の必要性を挙げる場合、最も重要な部分として、労働者にとって生活への影響度が高い労働条件である賃金と密接に関わっていることです。例えば遅刻早退、時間外労働があった際には、前者は欠勤控除をかけ、後者は残業代の支払いとなります。
        <br />
        勤怠は賃金を計算するにあたって計算の根拠となるものであり、併せて、労働法制上も客観的な方法で労働時間の管理が求められています。
        <br />
        <br />
        勤怠管理の問題点として、労働時間の概念がない<span>「管理監督者」</span>
        は勤怠管理不要との認識を持っている企業がありますが、そのようなことはなく、単に賃金計算において残業代の支払いがないことと、日々何時間程度働いているのかの記録を残すことは目的が異なります。後者は健康管理を把握する意味でも欠かすことはできません。
        <br />
        管理監督者であっても生身の人間であり、会社にどれくらい在社しているのか等の記録がない状態では、万が一、過労により働けなくなった場合に、会社として責任を問われることとなります。
      </p>
      <h1>テレワーク時の勤怠管理</h1>
      <p>
        テレワークとは離れた場所で働くことを指しているに過ぎず、会社からの指揮命令は存在し、
        <span>労働者は会社が求める労務の提供</span>
        をしなければなりません。よって、始業および終業の時刻、休憩時間の記録は必要です。
        <br />
        問題点としては、テレワークの形態が自宅の場合、周囲から完全に孤立しており、始業時刻や終業時刻が労働者の裁量によって、変動しやすいことです。また、直接的な周囲のサポートや注意もないことから、気づいたときには深夜帯に入って業務を行っていたというケースもあります。
        <br />
        <span>時間外労働</span>
        とは特定の時間の長さを指すことであり、1日8時間・週40時間を超えた分の労働に対して割増賃金を支払う構造になっています。しかし、深夜労働とは22時から翌朝5時までの特定の時間帯を指しており、時間の長さによって割増賃金を支払うという構造ではないため、その時間帯に業務を行うと割増賃金を支払わなければならないということです。
        <br />
        <br />
        テレワークの場合、性格的にルーズな従業員の場合、許可なく深夜帯に業務をすることのないよう繰り返し、指導しておく必要があります。
        <br />
        <br />
        次に、テレワークを自宅で行う場合、主目的が生活を営む場で業務を行うことから、突発的に中抜けが発生することがあります。その場合、中抜け時間は労働時間ではありませんので、賃金支払い義務は生じません。
        <br />
        実務上、中抜け時間が発生した場合、事前の労使間での取り決めで、終業時刻を後ろにずらすことや、中抜け時間を休憩時間として扱うこと、時間単位の有給休暇として扱うことなどが挙げられますが、合意だけでなく、勤怠管理上も判別ができなければ管理が行き届いているとは言えません。
        <br />
        多くの場合、テレワークではクラウド型の勤怠管理ソフトを導入することが増えていますが、複数日続けて打刻がされていない場合等、不自然な打刻が確認できる場合は、アラート表示が出る機能を設けておくなどの対策が必要です。
      </p>
      <Introduction003 />
      <h1 className="read">通常勤務時の勤怠管理</h1>
      <p>
        通常勤務の場合、選択肢としては使用者が自ら現認することにより確認する方法もありますが、現認するには常に使用者が労働者よりも早く出社し、遅く帰社しなければ現認できませんので、現実的な方法とは言えません。
        <br />
        次の選択肢としては客観的な記録で確認することとなります。これはテレワーク時であっても同様に一例として、クラウド型の勤怠管理システムを活用して記録する方法が挙げられますが、多くの会社で採用されている選択肢と言えます。
        <br />
        通常勤務時の勤怠管理について、（テレワークであれば起こりにくい現象ですが）通常勤務時は周囲で勤務する労働者との雑談により、退勤時刻が遅くなることです。賃金は労働時間に対して支払うもので、雑談のような滞留時間に対して発生するものではありません。
        <br />
        しかし、勤怠記録には時刻だけしか記録されないのが一般的ですので、その時間が労働時間なのか滞留時間なのかは労働者から申告を受けなければ判断できない場合も少なくありません。よって、業務終業後には速やかに帰宅し、賃金は労働時間に対して支払う旨を確認の意味で周知するなどの対応が求められます。
      </p>
      <h1>最後に</h1>
      <p>
        在宅・通常に関わらず、前述したような理由で、どんな記録方法であっても実働と打刻時間の乖離が発生することがあります。よって、打刻の他にPCのログイン・ログアウト記録等を
        <span>定期的に確認できるような仕組み</span>
        を整え、適切な運用が出来ていない場合には、管理者からヒアリングをするなど対応が適切です。
      </p>
    </section>
  </Wrapper>
)

export default ColumnDetailInner0009
